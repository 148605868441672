@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	h1 {
		@apply text-2xl font-bold leading-7 text-ed-black sm:truncate;
	}
	h2 {
		@apply text-xl font-bold leading-7 text-ed-black sm:truncate;
	}
	h3 {
		@apply text-lg font-bold leading-7 text-ed-black sm:truncate;
	}
	h4 {
		@apply text-base font-bold leading-7 text-ed-black sm:truncate;
	}
}

@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
	.thin-scrollbar::-webkit-scrollbar {
		height: 5px;
	}
	.thin-scrollbar::-webkit-scrollbar-track {
		background-color: #ffffff;
	}
	.thin-scrollbar::-webkit-scrollbar-thumb {
		background-color: #e4e4e4;
    	border-radius: 100px;
	}
}

.word-breaks {
	word-wrap: break-word;
	word-break: break-word;
}

body {
	overflow-y: scroll;
  }
